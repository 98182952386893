import { useContext, useMemo } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Navigation } from 'swiper/modules'

import {
  IRegistrationStateValue,
  RegistrationContext,
} from './RegistrationBaseWrapper'
import {
  TMetaobjectConfig,
  TUpsellInVenueProduct,
} from '../../interfaces/MetaobjectType'

const InVenue = () => {
  const registrationContext = useContext(
    RegistrationContext
  ) as IRegistrationStateValue
  const { metaobjectConfig } = registrationContext

  const { generalConfig } = metaobjectConfig as TMetaobjectConfig

  const upsellTitle: string = useMemo(
    () => generalConfig?.upsellInVenueProductsInfo?.promotionText?.title,
    [generalConfig?.upsellInVenueProductsInfo?.promotionText?.title]
  )

  const upsellDescription: string = useMemo(
    () => generalConfig?.upsellInVenueProductsInfo?.promotionText?.description,
    [generalConfig?.upsellInVenueProductsInfo?.promotionText?.description]
  )

  const upsellProducts: TUpsellInVenueProduct[] = useMemo(
    () => generalConfig?.upsellInVenueProductsInfo?.products,
    [generalConfig?.upsellInVenueProductsInfo?.products]
  )

  return (
    <div className="mt-5 relative z-50">
      {upsellTitle && (
        <h2 className="text-center text-black text-[20px] md:text-[30px] font-[700] py-5">
          {upsellTitle}
        </h2>
      )}

      {upsellDescription && (
        <p className="text-center text-[#777] text-[14px] md:px-[60px] leading-4">
          {upsellDescription}
        </p>
      )}

      {upsellProducts?.length > 0 && (
        <Swiper navigation={true} modules={[Navigation]}>
          {upsellProducts.map((upsellProduct: TUpsellInVenueProduct) => (
            <SwiperSlide key={upsellProduct.productId}>
              <div className="w-full flex flex-col gap-3.5">
                {upsellProduct?.productImageUrl && (
                  <div className="max-w-[200px] w-full max-h-[200px] h-full mx-auto overflow-hidden">
                    <img
                      src={upsellProduct.productImageUrl}
                      alt={upsellProduct.productTitle}
                      className="my-[20px] max-w-[180px] w-full max-h-[180px] h-full object-contain"
                    />
                  </div>
                )}

                <h2 className="text-center text-black text-[20px] md:text-[30px] font-[700]">
                  {upsellProduct.productTitle}
                </h2>
                <strong className="text-center text-black text-[20px] md:text-[30px] font-[400]">
                  {upsellProduct.productAmount}
                </strong>
                <button
                  onClick={() => {
                    if (typeof window !== 'undefined') {
                      window.open(upsellProduct.productUrl, '_blank')
                    }
                  }}
                  className="w-fit self-center items-center bg-[linear-gradient(290deg,#F908FF_0%,#D32EFE_8.19%,#37CCFB_87.88%,#05FFFA_100%)] font-semibold py-[8px] px-[30px] capitalize text-[16px] rounded-full text-white"
                >
                  Buy Now
                </button>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  )
}

export default InVenue
