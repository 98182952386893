import { useContext, useMemo, useState } from 'react'
import {
  SCREEN_NAMES,
  OCCASION_HEADER,
  OCCASION_DESCRIPTION,
} from './Constants'
import {
  IRegistrationStateValue,
  RegistrationContext,
} from './RegistrationBaseWrapper'
import Header from './Header'
import { useNavigate } from 'react-router-dom'
import { postRequest } from '../../api'
import { TOccasionOption } from '../../interfaces/MetaobjectType'
const OccasionOption = () => {
  const registrationContext = useContext(
    RegistrationContext
  ) as IRegistrationStateValue
  const { dispatch, orderId, metaobjectConfig } = registrationContext
  let navigate = useNavigate()
  const [selectedOccasion, setSelectedOccasion] = useState<string | null>(null)

  const headerDescription: string = useMemo(() => {
    if (metaobjectConfig?.generalConfig?.occasionScreenV2?.description) {
      return metaobjectConfig?.generalConfig?.occasionScreenV2?.description
    }
    return OCCASION_DESCRIPTION
  }, [metaobjectConfig])

  const headerTitle: string = useMemo(() => {
    if (metaobjectConfig?.generalConfig?.occasionScreenV2?.title) {
      return metaobjectConfig?.generalConfig?.occasionScreenV2?.title
    }
    return OCCASION_HEADER
  }, [metaobjectConfig])

  const occasionOptions: TOccasionOption[] = useMemo(() => {
    if (metaobjectConfig?.generalConfig?.occasionScreenV2) {
      return metaobjectConfig?.generalConfig?.occasionScreenV2?.options
    }
    return []
  }, [metaobjectConfig])

  const handleAddOrderTag = async () => {
    if (!selectedOccasion && !orderId) {
      console.error('==== Occasion or orderid is not available ====')
      return
    }

    dispatch({
      type: 'SET_LOADING',
      payload: {
        loading: true,
      },
    })

    try {
      const { data } = await postRequest('bookingOccasion', {
        orderId: orderId,
        attribution: selectedOccasion?.toLowerCase(),
      })

      if (data) {
        dispatch({
          type: 'SET_LOADING',
          payload: {
            loading: false,
          },
        })

        navigate(`/bookings/${orderId}/register-driver`)
        dispatch({
          type: 'SET_CURRENT_NAVIGATION',
          payload: {
            navigation: {
              currentScreen: SCREEN_NAMES.EMAIL_SELECTION_SCREEN,
              previousScreen: SCREEN_NAMES.OCCASION_SCREEN,
            },
          },
        })
      }
    } catch (error) {
      dispatch({
        type: 'SET_LOADING',
        payload: {
          loading: false,
        },
      })
      console.error('Error in handleAddToSession:', error)
    }
  }

  return (
    <>
      <Header title={headerTitle} description={headerDescription} />
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          {occasionOptions.slice(0, -1).map(option => (
            <div
              key={option.label}
              className={`relative h-32 rounded-lg overflow-hidden hover:cursor-pointer group ${
                selectedOccasion === option.label
                  ? 'p-[2px] bg-[linear-gradient(290deg,#F908FF_0%,#D32EFE_8.19%,#37CCFB_87.88%,#05FFFA_100%)]'
                  : ''
              }`}
              onClick={() => setSelectedOccasion(option.label)}
            >
              <div
                className={`relative h-full w-full rounded-lg overflow-hidden ${
                  selectedOccasion === option.label ? 'bg-white' : ''
                }`}
              >
                {option.videoUrl ? (
                  <video
                    className="absolute inset-0 w-full h-full object-cover"
                    src={option.videoUrl}
                    autoPlay
                    loop
                    muted
                    playsInline
                  />
                ) : (
                  <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{ backgroundImage: `url(${option.image})` }}
                  />
                )}

                <div className="absolute inset-0 bg-black/40 group-hover:bg-black/50 transition-colors" />
                <div className="absolute inset-0 flex items-center justify-center">
                  <span className="text-white font-semibold text-[1.5rem]">
                    {option.label}
                  </span>
                </div>
                {selectedOccasion && selectedOccasion === option.label && (
                  <div className="absolute top-2 right-2">
                    <span className="h-5 w-5 bg-[#9d62fecc] rounded-lg flex justify-center items-center">
                      <svg
                        width="14"
                        height="10"
                        viewBox="0 0 14 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 5L5 9L13 1"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        {occasionOptions.length > 0 && (
          <div
            key={occasionOptions[occasionOptions.length - 1].label}
            className={`relative h-32 rounded-lg overflow-hidden hover:cursor-pointer group w-full ${
              selectedOccasion ===
              occasionOptions[occasionOptions.length - 1].label
                ? 'p-[2px] bg-[linear-gradient(290deg,#F908FF_0%,#D32EFE_8.19%,#37CCFB_87.88%,#05FFFA_100%)]'
                : ''
            }`}
            onClick={() =>
              setSelectedOccasion(
                occasionOptions[occasionOptions.length - 1].label
              )
            }
          >
            <div
              className={`relative h-full w-full rounded-lg overflow-hidden ${
                selectedOccasion ===
                occasionOptions[occasionOptions.length - 1].label
                  ? 'bg-white'
                  : ''
              }`}
            >
              {occasionOptions[occasionOptions.length - 1].videoUrl ? (
                <video
                  className="absolute inset-0 w-full h-full object-cover"
                  src={occasionOptions[occasionOptions.length - 1].videoUrl}
                  autoPlay
                  loop
                  muted
                  playsInline
                />
              ) : (
                <div
                  className="absolute inset-0 bg-cover bg-center"
                  style={{
                    backgroundImage: `url(${
                      occasionOptions[occasionOptions.length - 1].image
                    })`,
                  }}
                />
              )}
              <div className="absolute inset-0 bg-black/40 group-hover:bg-black/50 transition-colors" />
              <div className="absolute inset-0 flex items-center justify-center">
                <span className="text-white font-semibold text-[1.5rem]">
                  {occasionOptions[occasionOptions.length - 1].label}
                </span>
              </div>
              {selectedOccasion &&
                selectedOccasion ===
                  occasionOptions[occasionOptions.length - 1].label && (
                  <div className="absolute top-2 right-2">
                    <span className="h-5 w-5 bg-[#9d62fecc] rounded-lg flex justify-center items-center">
                      <svg
                        width="14"
                        height="10"
                        viewBox="0 0 14 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 5L5 9L13 1"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                )}
            </div>
          </div>
        )}
      </div>

      <button
        onClick={handleAddOrderTag}
        disabled={!selectedOccasion}
        className={`w-fit self-center items-center bg-[linear-gradient(290deg,#F908FF_0%,#D32EFE_8.19%,#37CCFB_87.88%,#05FFFA_100%)] mt-8 font-semibold py-[15px] px-[25px] text-[16px] rounded-full text-white sm:min-w-[335px] min-w-[100%] ${
          !selectedOccasion ? 'opacity-50 cursor-not-allowed' : 'opacity-100'
        }`}
      >
        Continue
      </button>
    </>
  )
}

export default OccasionOption
